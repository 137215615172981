import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { ProductService } from 'src/app/services/product.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'registration',
    templateUrl: './registration.html'
})
export class RegistrationComponent {

    @Output() changeForm = new EventEmitter<string>();
    constructor (
        public formService: FormService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private jsonDataService: JsonDataService,
        private userDetailsService: UserDetailsService,
        private paramService: ParameterService,
        private productService: ProductService,
        private securityService: SecurityService,
        private router: Router
    ) {}

    subform:string = "register";

    varChildCommon:any = {
        email: "",
        registerFunction: (params: any) => {
            return this.register(params);
        },
        goToForm: (formName: string) => {
            return this.goToForm(formName);
        },
        verifyFunction: (email: string) => {
            return this.verify(email);
        }
    }

    register(params: any): void {
        this.apiCallService.register(params).subscribe((response:any) => {
            const res = JSON.parse(this.jsonDataService.decrypt(response.response));

            if (response.status === "SUCCESS") {
                this.securityService.hasValidCsrfToken(response, () => {
                    this.appMessageService.showAppMessage("Registration successful. An email has been sent for verification.", "success");
                    this.subform = "verify";
                    this.varChildCommon.email = params.email || null;

                    if (res.bssid) {
                        console.log("registration.ts 57: ", res.bssid);
                        sessionStorage.setItem("bssid", res.bssid);
                        sessionStorage.setItem("rkc", "1");

                        this.userDetailsService.setUserDetails ({
                            accountType: "P",
                            userId: res.userId,
                            name: params.firstName || params.companyName,
                            email: params.email,
                            expiry: res.expiry,
                            windowId: 0,
                            premTag: "",
                            agentGrp: "",
                            userLevel: "0",
                            issCd: this.paramService.paramV("PU_ISS_CD"),
                            verificationTag: "N",
                            unchangedPw: "Y",
                            mobileNo: params.mobileNo,
                            displayName: params.displayName
                        });

                        if (["/register", "/login"].indexOf(this.router.url) >= 0) {
                            this.jsonDataService.contorlLoading(false);
                        } else {
                            if (this.productService.productInfo.lineCd === "MC" || this.productService.productInfo.lineCd === "HA") {
                                this.saveQuotationDetails();
                            } else if (["PA", "CI"].indexOf(this.productService.productInfo.lineCd) >= 0) {
                                this.saveCoverageDetails();
                            }

                            this.changeForm.emit("register");
                        }
                    } else {
                        this.jsonDataService.contorlLoading(false);
                    }
                });
            } else {
                this.jsonDataService.contorlLoading(false);
                this.formService.showFormMsg("registration-form-msg", res.errorMessage, "E");
            }
        });
    }

    verify(email: string):void {
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendRecoveryEmail("verify", email).subscribe((response: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.appMessageService.showAppMessage("Email has been sent for verification.", "success");
                    this.subform = "verify";
                } else {
                    this.formService.showFormMsg("registration-form-msg", response.message, "E");
                }
            });
        });
    }

    goToForm(formName: string) {
        this.subform = formName;
    }

    saveQuotationDetails():void {
        const productInfo = this.productService.productInfo;
        if (productInfo.lineCd === 'MC'){    
            const vehicleInfo = productInfo.vehicleInfo;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.saveVehicleDetails({
                quoteId: null,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                pvCd: productInfo.prodVouchInfo?.prodVouchCd,
                mortAccTag: vehicleInfo.mortgageType,
                bankCd: vehicleInfo.bankDetail.value,
                lanNo: vehicleInfo.loanAccountNo,
                alfesNo: vehicleInfo.alfesNo,
                veTypeTag: vehicleInfo.vehicleType,
                plateNo: vehicleInfo.plateNo,
                modelYear: vehicleInfo.yearModelDetail.value,
                carCompanyCd: vehicleInfo.makeDetail.value,
                makeCd: vehicleInfo.modelDetail.value,
                seriesCd: vehicleInfo.subModelDetail.value,
                purchasePrice: vehicleInfo.purchasePrice,
                color: vehicleInfo.color,
                seatsCovered: vehicleInfo.seatsCovered,
                engineNo: vehicleInfo.engineNo,
                chassisNo: vehicleInfo.chassisNo,
                mvFileNo: vehicleInfo.mvFileNo,
                mvType: vehicleInfo.mvTypeDetail.value,
                mvPremType: vehicleInfo.mvPremTypeDetail.value,
                veUsageCd: vehicleInfo.beingUsedDetail.value,
                remarks: vehicleInfo.remarks,
                creationProcess: productInfo.creationProcess,
                supportingDocs: vehicleInfo.supportingDocs,
                genPageWord: vehicleInfo.genPageWord
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            sessionStorage.setItem("rid", res.recordId);

                            this.productService.setProductInfo("quoteId", res.quoteId);
                            this.saveCoverageDetails();
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage("Error saving your vehicle details.", "error");
                        }
                    });
                });
            });
        } else if (productInfo.lineCd === 'HA') {
            const propertyInfo = productInfo.propertyInfo;
            const propertyAssessment = productInfo.propertyAssessment;
            const bldg = productInfo.bldgForm;
            const cont = productInfo.contForm;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.savePropertyDetails({
                quoteId: null,
                pvCd: productInfo.prodVouchInfo?.prodVouchCd,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                addType: null,
                compAdd: propertyInfo.compAdd || null,
                houseNo: propertyInfo.houseNo,
                street: propertyInfo.street,
                brgy: propertyInfo.brgy,
                subdivName: propertyInfo.subdivName,
                country: propertyInfo.country.value,
                provOrCity: propertyInfo.provOrCity.provinceCd,
                cityCd: propertyInfo.provOrCity.value,
                postCode: propertyInfo.postCode.value,
                subdivCd: propertyAssessment.subdivision.value || 0,
                zoneCd: propertyAssessment.subdivision.zoneCd || 1,
                latitude: propertyInfo.latitude || "",
                longitude: propertyInfo.longitude || "",
                currency: propertyInfo.currency,
                buildings: bldg.bldgItms,
                contents: cont.contItms,
                bldgTag: propertyInfo.bldgTag,
                bldgInsuredAmt: propertyInfo.bldgInsuredAmt || "",
                contTag: propertyInfo.contTag,
                contInsuredAmt: propertyInfo.contInsuredAmt || "",
                constYear: propertyInfo.constYear || "",
                yrBuiltCd: propertyInfo.yrBuiltCd.value || 0,
                ageBldg: propertyInfo.ageBldg || "",
                noOfFloor: propertyInfo.noOfFloor || "",
                nearLand: propertyInfo.nearLand || "",
                roofPropDtl: Number(propertyInfo.roofPropDtl),
                roofOthers: propertyInfo.roofOthers || "",
                wallPropDtl: Number(propertyInfo.wallPropDtl),
                wallOthers: propertyInfo.wallOthers || "",
                mortAccTag: propertyInfo.mortAccTag || "",
                bank: propertyInfo.bank.value || "",
                loanAcctNo: propertyInfo.loanAcctNo || "",
                bankPolDoc: propertyInfo.bankPolDoc || "",
                existInsTag: propertyInfo.existInsTag || "",
                existInsComp: propertyInfo.existInsComp || "",
                existInsBldgTag: propertyInfo.existInsBldgTag || "",
                existInsContTag: propertyInfo.existInsContTag || "",
                occupancyF: propertyInfo.occupancyF || "",
                occupancyR: propertyInfo.occupancyR || "",
                occupancyL: propertyInfo.occupancyL || "",
                occupancyB: propertyInfo.occupancyB || "",
                storeyF: propertyInfo.storeyF || "",
                storeyR: propertyInfo.storeyR || "",
                storeyL: propertyInfo.storeyL || "",
                storeyB: propertyInfo.storeyB || "",
                roofF: propertyInfo.roofF || "",
                roofR: propertyInfo.roofR || "",
                roofL: propertyInfo.roofL || "",
                roofB: propertyInfo.roofB || "",
                exWallF: propertyInfo.exWallF || "",
                exWallR: propertyInfo.exWallR || "",
                exWallL: propertyInfo.exWallL || "",
                exWallB: propertyInfo.exWallB || "",
                surroundF: propertyInfo.surroundF || "",
                surroundR: propertyInfo.surroundR || "",
                surroundL: propertyInfo.surroundL || "",
                surroundB: propertyInfo.surroundB || "",
                remarks: propertyInfo.remarks || "",
                roofClassCd: propertyAssessment.roof.classCd,
                wallClassCd: propertyAssessment.wall.classCd,
                creationProcess: productInfo.creationProcess,
                supportingDocs: propertyInfo.supportingDocs,
                genPageWord: propertyInfo.genPageWord,
                saveContent: true
            }).subscribe((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (propertyAssessment.suppDocs && propertyAssessment.suppDocs.length > 0) {
                                this.uploadSupportingDocs(0, response, propertyAssessment);
                            } else {
                                sessionStorage.setItem("rid", res.recordId);
                                this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                                this.productService.setProductInfo("quoteId", res.quoteId);
                                this.saveCoverageDetails();
                            }
                        });
                    } else {
                        this.jsonDataService.contorlLoading(false);
                        this.appMessageService.showAppMessage("Error saving your property details.", "error");
                    }
                });
            });
        }
    }

    async uploadSupportingDocs(index: number, response: any, propertyAssessment: any): Promise<void> {
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        this.jsonDataService.contorlLoading(true);
        const base64String = await this.formService.getFileAsBase64(propertyAssessment.suppDocs[index]);
        this.apiCallService.getPresignedUrl({
            action: "putObject",
            directory: `supporting-docs/property-details/${res.quoteId}/${res.itemNo}`,
            filename: propertyAssessment.suppDocs[index].name,
            validation: "VehicleSuppDoc",
            base64FileString: base64String
        }).subscribe((getPresignedUrlResponse: any) => {
            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                this.jsonDataService.contorlLoading(false);
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.apiCallService.uploadPresignedUrl(
                                res.presignedUrl, propertyAssessment.suppDocs[index]
                            ).subscribe((uploadPresignedUrlResponse: any) => {
                                if (index === (propertyAssessment.suppDocs.length-1)) {
                                    sessionStorage.setItem("rid", res.recordId);
                                    this.productService.setProductInfo("policyHeaderDetails", res.headerDetails);
                                    this.productService.setProductInfo("quoteId", res.quoteId);
                                    this.saveCoverageDetails();
                                } else {
                                    this.uploadSupportingDocs(index+1, response, propertyAssessment);
                                }
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            //this.supportingDocsFiles = [];
                            //this.form.controls["supportingDocs"].setValue([]);
                            this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your property details. Please try again later.", "error");
                }
            });
        });
    }

    saveCoverageDetails():void {
        const productInfo = this.productService.productInfo;
        if (['MC', 'HA'].includes(productInfo.lineCd)) {
            this.apiCallService.saveCoverage({
                quoteId: productInfo.quoteId,
                tempId: productInfo.coverageInfo.tempId,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.appMessageService.showAppMessage("Registered successfully and your quotation details have been saved.", "success");
                    } else {
                        this.appMessageService.showAppMessage("Error saving your coverage details.", "error");
                    }
                });
            });
        } else if (productInfo.lineCd === "PA") {
            this.apiCallService.saveCoverage({
                quoteId: productInfo.quoteId,
                tempId: productInfo.coverageInfo.tempId,
                lineCd: productInfo.lineCd,
                sublineCd: productInfo.sublineCd,
                planCd: productInfo.planInfo.planCd,
                promoCode: productInfo.coverageInfo.promoCode,
                userId: this.userDetailsService.userId,
                policyHeaderInfo: productInfo.policyHeaderDetails
            }).subscribe((response:any) => {
                this.jsonDataService.contorlLoading(false);
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status === "SUCCESS") {
                        this.appMessageService.showAppMessage("Registered successfully and your quotation details have been saved.", "success");
                    } else {
                        this.appMessageService.showAppMessage("Error saving your coverage details.", "error");
                    }
                });
            });
        }
    }

}