import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private window;

  constructor(
    private windowRef: WindowReferenceService
  ) {
    this.window = windowRef.nativeWindow;
  }

  private pingHome(obj: any) {
    // if(obj) this.window.dataLayer.push(obj);
  }

  logPageView(pageName: any, pagePath: any, planName: any) {
    const hit = {
      event: 'content-view',
      pageName: pageName,
      pagePath: pagePath,
      planName: planName
    };
    this.pingHome(hit);
  }

  logEvent(event: any, category: any, action: any, label: any) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label
    };
    this.pingHome(hit);
  }

  logPurchase(id: any, value: any, tax: any, currency: any, coupon: any, items: any) {
    const hit = {
      event: 'purchase',
      transaction_id: id,
      value: value,
      tax: tax,
      currency: currency,
      coupon: coupon,
      items: items
    };
    this.pingHome(hit);
  }

  logSelectedContent(itemId: any) {
    const hit = {
      event: 'select_content',
      content_type: 'product',
      item_id: itemId
    };
    this.pingHome(hit);
  }

  logSelectedItem(itemListId: any, itemListName: any, items: any) {
    const hit = {
      event: 'select_item',
      item_list_id: itemListId,
      item_list_name: itemListName,
      items: items
    };
    this.pingHome(hit);
  }

  // .. add more custom methods as needed by your app.
}
