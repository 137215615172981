import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DeviceDetectorService } from 'ngx-device-detector';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
	selector: 'sub-otp',
  	templateUrl: './sub-otp.html'
})
export class SubOtpComponent {

    @Input() parentChildCommon:any;

    successOtp: boolean = false;
    withOtpMethod: boolean = false;
    otpMethod: string = "";
    otpReceiver: string = "";
    otpErrorMsg: string = "";
    noOfDays: string = "";
    daysToBypassOtp: number = 0;
    sendOtpInterval: number = 0;
    chkOTP: string = "chkResponse";
    pendingResponse: boolean = false;

    otpForm: FormGroup = this.formBuilder.group({
        otp: [""]
    });

  	constructor (
        private formBuilder: FormBuilder,
        private deviceDetectorService: DeviceDetectorService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private jsonDataService: JsonDataService,
        private parameterService: ParameterService,
        private securityService: SecurityService
    ) {
        this.daysToBypassOtp = this.parameterService.paramN("DAYS_TO_BYPASS_OTP") || 0;
        this.noOfDays = this.daysToBypassOtp + (this.daysToBypassOtp > 1 ? " days" : " day");

        setInterval(() => {
            if (this.sendOtpInterval > 0) {
                this.sendOtpInterval -= 1;
            }
        }, 1000);
    }

    public sendOtp(method: string, withTimeInterval: string): void {
        this.otpErrorMsg = "";
        console.log("sub-otp.ts 56: ", this.parentChildCommon.loginResponse.userAuth.bssid);
        sessionStorage.setItem("bssid", this.parentChildCommon.loginResponse.userAuth.bssid);
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.sendOtp({
            name: this.parentChildCommon.loginResponse.name,
            method: method,
            withTimeInterval: withTimeInterval
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.jsonDataService.contorlLoading(false);
                if (response.status === "SUCCESS") {
                    this.sendOtpInterval = this.parameterService.paramN("OTP_REQUEST_INTERVAL");
                    this.withOtpMethod = true;
                    this.otpMethod = method;
                    this.otpForm.controls["otp"].setValue("");

                    if (method === "mobile") {
                        this.otpReceiver = this.parentChildCommon.loginResponse.userAuth.mobileNo;
                    } else {
                        this.otpReceiver = this.parentChildCommon.loginResponse.userAuth.email;
                    }
                } else {
                    this.appMessageService.showAppMessage("There's something wrong sending your OTP. Please try again later.", "error");
                }
            });
        });
    }

    public verifyOTP(): void {
        if (this.otpForm.controls["otp"].value && !this.pendingResponse) {
            this.otpErrorMsg = "";
            console.log("sub-otp.ts 87: ", this.parentChildCommon.loginResponse.userAuth.bssid);
            sessionStorage.setItem("bssid", this.parentChildCommon.loginResponse.userAuth.bssid);
            this.jsonDataService.contorlLoading(true);
            this.pendingResponse = true;
            this.apiCallService.verifyOtp({
                otp: this.otpForm.controls["otp"].value
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        this.jsonDataService.contorlLoading(false);
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        if (res === "SUCCESS") {
                            if (this.daysToBypassOtp > 0) {
                                console.log("sub-otp.ts 100: ", this.parentChildCommon.loginResponse.userAuth.bssid)
                                sessionStorage.setItem("bssid", this.parentChildCommon.loginResponse.userAuth.bssid);
                                this.jsonDataService.contorlLoading(true);
                                let randomId = this.apiCallService.generateId();
                                this.apiCallService.security({chk: this.chkOTP, id: randomId}).subscribe((data: any) => {
                                    this.securityService.checkRequestKeyResponse(data, () => {
                                        this.securityService.hasValidCsrfToken(data, () => {
                                            this.jsonDataService.contorlLoading(false);
                                            const sec = JSON.parse(this.jsonDataService.decrypt(data.response));
                                            if (sec.randomId === randomId) {
                                                if (sec.response === "PASSED") {
                                                    this.successOtp = true;
                                                }
                                            } else {
                                                this.otpErrorMsg = "Sorry, you provided the wrong OTP. Please try again or resend another OTP.";
                                                this.pendingResponse = false;
                                            }
                                        });  
                                    });
                                });
                            } else {
                                this.parentChildCommon.proceedLogin(this.parentChildCommon.loginResponse);
                            }
                        } else if (res === "WRONG_OTP") {
                            this.otpErrorMsg = "Sorry, you provided the wrong OTP. Please try again or resend another OTP.";
                            this.pendingResponse = false;
                        } else if (res === "EXPIRED") {
                            this.otpErrorMsg = "Sorry, the OTP you’ve keyed in has expired. Please click Resend OTP and try again.";
                            this.pendingResponse = false;
                        } else {
                            this.otpErrorMsg = "An active session is on-going.";
                            this.pendingResponse = false;
                        }
                    });
                });
            });
        } else {
            this.otpErrorMsg = "Complete the OTP to help us verify your identity.";
        }
    }

    public signInAnotherWay(): void {
        this.otpErrorMsg = "";
        this.withOtpMethod = false;
    }

    public cancelOTP(): void {
        this.parentChildCommon.goToForm("login");
    }

    public saveOtpToken(): void {
        const tokenValue = this.jsonDataService.encrypt({
            userId: this.parentChildCommon.loginResponse.userId,
            browser: this.deviceDetectorService.browser,
            datetime: this.generateDateTime()
        });
        console.log("sub-otp.ts 156: ", this.parentChildCommon.loginResponse.userAuth.bssid);
        sessionStorage.setItem("bssid", this.parentChildCommon.loginResponse.userAuth.bssid);
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.saveOtpToken({chk: this.chkOTP}).subscribe((data: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(data, () => {
                this.securityService.hasValidCsrfToken(data, () => {
                    this.jsonDataService.contorlLoading(false);
                    const sec = JSON.parse(this.jsonDataService.decrypt(data.response));
                    localStorage.setItem(this.createToken(tokenValue), sec.token);
                    this.parentChildCommon.proceedLogin(this.parentChildCommon.loginResponse);
                });  
            });
        });
        // localStorage.setItem(this.createToken(tokenValue), tokenValue);
        // this.parentChildCommon.proceedLogin(this.parentChildCommon.loginResponse);
    }

    public proceedLogin(): void {
        this.parentChildCommon.proceedLogin(this.parentChildCommon.loginResponse);
    }

    private createToken(data: string): string {
        return this.jsonDataService.encrypt(
            data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) +
            data.substr(15, 1) + data.substr(35, 1) + data.substr( 5, 1) +
            data.substr(25, 1) + data.substr(20, 1));
    }

    private generateDateTime(): string {
        const date = new Date().toLocaleDateString("en-US").split("/");
        const year = date[2], month = date[0], day = date[1];

        const hour = ("0" + (new Date().getHours() || 0)).slice(-2);
        const minute = ("0" + (new Date().getMinutes() || 0)).slice(-2);
        const second = ("0" + (new Date().getSeconds() || 0)).slice(-2);

        return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    }
}