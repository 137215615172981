<section id="vehicle-details">
    <div class="container">
        <form [formGroup]="form">
            <div id="vehicle-details-form">
                <div class="form-loader"></div>
                <div class="form-fields">
                    <div class="form" *ngIf="productSublineCd != 'CTPL'">
                        <div class="vehicle-details-form-msg top"></div>
                        <div class="col-case">
                            <div class="field">
                                <label class="rdo-label">Is the vehicle on mortgage?</label>
                                <mat-radio-group formControlName="mortgageType" class="mortgage-type-radio-group" (change)="initializeVehicleMortgageDependents($event.value)">
                                    <mat-radio-button class="mortgage-type-radio-button" value="Y">Yes</mat-radio-button>
                                    <mat-radio-button class="mortgage-type-radio-button" value="N">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-case col-3" *ngIf="showVehicleMortgageDependents">
                            <div class="field" [class.required]="requiredBank">
                                <label>Bank / Lender Name</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Bank Name" formControlName="bankDetail" [matAutocomplete]="autoBankDetail" (input)="setBankViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoBankDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setBankViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredBankDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'bankDetail')">
                                    {{formService.getFieldErrorMsg(form, "bankDetail")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="requiredLoanAccountNo">
                                <label>Loan Account No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Loan Account No." formControlName="loanAccountNo" maxlength="20">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'loanAccountNo')">
                                    {{formService.getFieldErrorMsg(form, "loanAccountNo")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="requiredAlfesNo" *ngIf="showBankNameDependent">
                                <label>ALFES No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="ALFES No." formControlName="alfesNo" maxlength="20">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'alfesNo')">
                                    {{formService.getFieldErrorMsg(form, "alfesNo")}}
                                </p>
                            </div>
                        </div>
                        <div class="form-note" *ngIf="showVehicleMortgageDependents">
                            <p>{{bankCaveat}}</p>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case">
                            <div class="field required">
                                <label class="rdo-label">Select Vehicle Type</label>
                                <mat-radio-group formControlName="vehicleType" class="vehicle-type-radio-group" (change)="initializeVehicleTypeDependent($event.value, true)">
                                    <mat-radio-button class="vehicle-type-radio-button" value="1">Brand New</mat-radio-button>
                                    <mat-radio-button class="vehicle-type-radio-button" value="2">Used Vehicle</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field" [class.required]="requiredPlateNo">
                                <label>Plate No.{{productSublineCd !== 'CTPL' ? ' / Conduction No.' : ''}}</label>
                                <mat-form-field appearance="fill">
                                    <input matInput [placeholder]="'Plate No.' + (productSublineCd !== 'CTPL' ? ' / Conduction No.' : '')" formControlName="plateNo" maxlength="10" class="all-caps letters-numbers">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'plateNo')">
                                    {{formService.getFieldErrorMsg(form, "plateNo")}}
                                </p>
                            </div>
                            <div class="field required">
                                <label>Year Model</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="Year Model" formControlName="yearModelDetail" [matAutocomplete]="autoYearModelDetail" (input)="setYearModelViaInput()" autocomplete>
                                    <mat-autocomplete #autoYearModelDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setYearModelViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredYearModelDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'yearModelDetail')">
                                    {{formService.getFieldErrorMsg(form, "yearModelDetail")}}
                                </p>
                            </div>
                            <div class="field required">
                                <label>Brands</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setMakePlaceholder()" formControlName="makeDetail" [matAutocomplete]="autoMakeDetail" (input)="setMakeViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoMakeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setMakeViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredMakeDetailOptions | async" [value]="option" [disabled]="!option.value">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'makeDetail')">
                                    {{formService.getFieldErrorMsg(form, "makeDetail")}}
                                </p>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field required">
                                <label>Model</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setModelPlaceholder()" formControlName="modelDetail" [matAutocomplete]="autoModelDetail" (input)="setModelViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoModelDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setModelViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredModelDetailOptions | async" [value]="option" [disabled]="!option.value">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'modelDetail')">
                                    {{formService.getFieldErrorMsg(form, "modelDetail")}}
                                </p>
                            </div>
                            <div class="field required">
                                <label>Variant</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput [placeholder]="setSubModelPlaceholder()" formControlName="subModelDetail" [matAutocomplete]="autoSubModelDetail" (input)="setSubModelViaInput($event)" autocomplete>
                                    <mat-autocomplete #autoSubModelDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText" (optionSelected)="setSubModelViaOption($event.option)">
                                        <mat-option *ngFor="let option of filteredSubModelDetailOptions | async" [value]="option" [disabled]="!option.value" [attr.makeTypeCd]="option.makeTypeCd" [attr.ccWt]="option.ccWt" [attr.gasType]="option.gasType" [attr.seats]="option.seats" [attr.fmvMinValue]="option.fmvMinValue"
                                            [attr.contractTypeCd]="option.contractTypeCd" [attr.fmvMaxValue]="option.fmvMaxValue">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'subModelDetail')">
                                    {{formService.getFieldErrorMsg(form, "subModelDetail")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="requiredPurchaseValue" *ngIf="(showVehicleTypeDependent || showFmvUsed) && productSublineCd !== 'CTPL'">
                                <label>{{showVehicleTypeDependent ? 'Purchase Price' : 'Fair Market Value'}}</label>
                                <mat-form-field appearance="fill">
                                    <input matInput class="amount-field" [placeholder]="setPurchasePricePlaceholder()" formControlName="purchasePrice" amount #purchasePriceInput>
                                </mat-form-field>
                                <p class="field-error" *ngIf="validatePurchasePrice()">
                                    Vehicle value must be within the Fair Market Value limit.
                                </p>
                            </div>
                        </div>
                        <ng-container *ngIf="productInfo.creationProcess === 'quotation' else polBlock">
                            <div class="form-note">
                                <p>Can't find the brand of your vehicle? <a (click)="contactBPI()">Contact BPI MS</a></p>
                            </div>
                        </ng-container>
                        <ng-template #polBlock>
                            <div class="form-note">
                                <p>Do you want to change the insured vehicle? <a (click)="backToQuotationProcessing()">Click here to go back to quotation.</a>&nbsp;<a (click)="reprintQuote()">Reprint quote.</a></p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="form">
                        <div class="col-case col-3">
                            <div class="field" [class.required]="requiredColor">
                                <label>Exterior Color</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Exterior Color" formControlName="color" maxlength="60">
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>CC/WT</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="CC/WT" formControlName="ccWt">
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <label>Fuel Type</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Fuel Type" formControlName="gasDesc">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case col-3">
                            <div class="field">
                                <label>No. of Seats</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="No. of Seats" formControlName="noOfSeats">
                                </mat-form-field>
                            </div>
                            <div class="field" [class.required]="requiredSeats">
                                <label>Seats Covered</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Seats Covered" formControlName="seatsCovered">
                                </mat-form-field>
                                <p class="field-error" *ngIf="validateSeatsCovered()">
                                    {{seatsErrorMessage}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case col-3">
                            <div class="field" [class.required]="requiredEngineNo">
                                <label>Engine No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Engine No." formControlName="engineNo" maxlength="60" class="all-caps letters-numbers">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'engineNo')">
                                    {{formService.getFieldErrorMsg(form, "engineNo")}}
                                </p>
                            </div>
                            <div class="field" [class.required]="requiredChassisNo">
                                <label>Chassis No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="Chassis No." formControlName="chassisNo" maxlength="60" class="all-caps letters-numbers">
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'chassisNo')">
                                    {{formService.getFieldErrorMsg(form, "chassisNo")}}
                                </p>
                            </div>
                            <div class="field required" [class.required]="requiredMvFileNo" *ngIf="productSublineCd === 'CTPL' && this.form.get('vehicleType')?.value != 1">
                                <label>MV File No.</label>
                                <mat-form-field appearance="fill">
                                    <input matInput placeholder="MV File No." formControlName="mvFileNo" [minlength]="parameterService.paramN('MVFILENO_LENGTH')" uppercase [withSpace]="false" [withSpecialCharacters]="false">
                                </mat-form-field>
                                <ng-container *ngIf="mvFileNo?.invalid && (mvFileNo?.dirty || mvFileNo?.touched)">
                                    <p class="field-error" *ngIf="formService.isInvalid(form, 'mvFileNo')">
                                        {{formService.getFieldErrorMsg(form, "mvFileNo")}}
                                    </p>
                                    <p class="field-error" *ngIf="checkMvFileNoLength()">
                                        {{"MV File No. must be " + parameterService.paramN("MVFILENO_LENGTH") + " characters."}}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-case col-3" *ngIf="productSublineCd === 'CTPL'">
                            <div class="field" [class.required]="requiredMvType">
                                <label>MV Type</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="MV Type" formControlName="mvTypeDetail" [matAutocomplete]="autoMvTypeDetail" autocomplete>
                                    <mat-autocomplete #autoMvTypeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredMvTypeOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'mvTypeDetail')">
                                    {{formService.getFieldErrorMsg(form, "mvTypeDetail")}}
                                </p>
                            </div>
                            <div class="field col-2" [class.required]="requiredMvPremType">
                                <label>MV Prem Type</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="MV Prem Type" formControlName="mvPremTypeDetail" [matAutocomplete]="autoMvPremTypeDetail" (input)="setMVPremTypeViaInput()" autocomplete>
                                    <mat-autocomplete #autoMvPremTypeDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredMvPremTypeOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p class="field-error" *ngIf="formService.isInvalid(form, 'mvPremTypeDetail')">
                                    {{formService.getFieldErrorMsg(form, "mvPremTypeDetail")}}
                                </p>
                            </div>
                        </div>
                        <div class="form-note">
                            <p>Where to find these on your OR/CR? <a (click)="orcrOverlay.open('Return')">Click here</a></p>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-case col-3">
                            <div class="field" [class.required]="requiredMcUsage">
                                <label>How do you intend to use the vehicle?</label>
                                <mat-form-field class="select" appearance="fill">
                                    <input matInput placeholder="How do you intend to use the vehicle?" formControlName="beingUsedDetail" [matAutocomplete]="autoBeingUsedDetail" autocomplete>
                                    <mat-autocomplete #autoBeingUsedDetail="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                        <mat-option *ngFor="let option of filteredBeingUsedDetailOptions | async" [value]="option">
                                            {{option.text}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="field supporting-docs" *ngIf="displaySupportingDoc && userDetailsService.userId">
                                <label>Supporting Documents</label>
                                <button class="btn btn6 proxima" (click)="fileInput.click()">Upload File (Total file size up to 10MB)</button>
                                <input type="file" #fileInput (change)="onFileChange($event)" multiple hidden>
                                <p class="error-msg" *ngIf="supportDocErrorMsg">{{supportDocErrorMsg}}</p>
                                <div class="files" *ngFor="let a of form.controls['supportingDocs'].value; index as i">
                                    <p>{{a.filename}}</p>
                                    <div class="buttons">
                                        <p class="view" (click)="onClickView(i)">View</p>
                                        <p class="remove" (click)="onClickDelete(i)">Remove</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="userDetailsService.userId && userDetailsService.accountType !== 'P'">
                            <div class="field">
                                <label>Remarks (optional)</label>
                                <mat-form-field appearance="fill">
                                    <textarea matInput placeholder="Remarks" rows="3" formControlName="remarks" maxlength="1000"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-case" *ngIf="userDetailsService.userId && genPageUser.includes(userDetailsService.accountType)">
                            <div class="field">
                                <label>General Page Wordings</label>
                                <mat-form-field appearance="fill">
                                    <textarea matInput placeholder="General Page Wordings" rows="3" formControlName="genPageWord" maxlength="3750"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vehicle-details-form-msg bottom"></div>
            </div>
            <div class="form-btns">
                <button class="btn btn4" (click)="move('back')">Back</button>
                <button class="btn btn5" (click)="save()" *ngIf="userDetailsService.userId">Save</button>
                <button class="btn btn2" (click)="move('next')">{{nextLabel}}</button>
            </div>
        </form>
    </div>
</section>

<overlay #orcrOverlay id="orcr-title" [title]="'Sample OR/CR'">
    <div content id="orcr-overlay">
        <img [src]="imagePath">
    </div>
</overlay>

<overlay #duplicateVehicleOverlay [title]="withDuplicateMessageProceed ? 'Confirmation' : 'Information'">
    <div content>{{confirmDuplicateMessage}}</div>
    <button mat-button buttons #focusBtn class="btn btn-4" *ngIf="withDuplicateMessageProceed" (click)="saveVehicleDetails();duplicateVehicleOverlay.close()">Save</button>
    <button mat-button buttons class="btn" (click)="contactBPI();duplicateVehicleOverlay.close()" tabindex="0">Contact BPI MS</button>
</overlay>