import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalService {
    constructor() {}

    public storeQuotationData(args: any): void {
        const { type } = args || {}

        if (type) sessionStorage.setItem(`quotation_${type}`, JSON.stringify(args)) 
    }

    public getQuotationData(type: string): any {
        const quotationData = sessionStorage.getItem(`quotation_${type}`)

        return quotationData ? JSON.parse(quotationData) : {}
    }

    public destroyQuotationData(type: string): void {
        sessionStorage.removeItem(`quotation_${type}`)
    }
}
